import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'

import { SEO, Layout, Divider } from 'Components'

import './index.css'

dayjs.extend(localizedFormat)

export const HomeQuery = graphql`
  query {
    projects: allContentfulProject(filter: { featured: { eq: true } }) {
      totalCount
      edges {
        node {
          id
          slug
          name
          mainImage {
            title
            description
            fluid(maxWidth: 1200, quality: 85) {
              ...GatsbyContentfulFluid
              sizes
              srcSet
              sizes
              src
              srcSetWebp
            }
          }
          specs {
            program
            client
            programType
            author
          }
          orientation
          featured
        }
      }
    }
    education: allContentfulEducation(filter: { featured: { eq: true } }) {
      totalCount
      edges {
        node {
          id
          slug
          name
          author
          publishDate
          mainImage {
            title
            description
            fluid(maxWidth: 1200, quality: 85) {
              ...GatsbyContentfulFluid
              sizes
              srcSet
              sizes
              src
              srcSetWebp
            }
          }
          orientation
          featured
        }
      }
    }
    press: allContentfulPress(filter: { featured: { eq: true } }) {
      totalCount
      edges {
        node {
          id
          title
          description
          publishDate
          url
          featured
        }
      }
    }
  }
`

const Homepage = ({ data }) => {
  const { projects, education, press } = data
  const { edges: featuredProjects } = projects
  const { edges: featuredEducation } = education
  const { edges: featuredPress } = press

  return (
    <>
      <SEO title="Homepage" description="Welcome to Gitai Architects portfolio website." />

      <Layout>
        <div className="Homepage flex justify-center flex-wrap">
          {featuredProjects.map(({ node }) => {
            const { orientation, featured } = node
            return (
              <article
                key={node.id}
                orientation={orientation}
                className={`thumbnail ${featured ? 'thumbnail-featured' : ''} self-center p-4 xl:p-12`}>
                <figure className="thumbnail-container">
                  <Link className="thumbnail-holder block" to={`/projects/${node.slug}`}>
                    <Img
                      key={node.id}
                      title={node.mainImage.title}
                      alt={node.mainImage.title}
                      description={node.mainImage.description}
                      fluid={node.mainImage.fluid}
                    />
                  </Link>
                  <figcaption className="thumbnail-info mb-4 text-lg">
                    <Link to={`/projects/${node.slug}`} className="thumbnail-link">
                      {node.name}
                    </Link>
                    <p className="text-sm text-gray-400">{node.specs.author}</p>
                  </figcaption>
                </figure>
              </article>
            )
          })}
          {featuredEducation.map(({ node }) => {
            const { orientation, featured } = node
            return (
              <article
                key={node.id}
                orientation={orientation}
                className={`thumbnail ${featured ? 'thumbnail-featured' : ''} self-center p-4 xl:p-12`}>
                <figure className="thumbnail-container">
                  <Link className="thumbnail-holder block" to={`/education/${node.slug}`}>
                    <Img
                      key={node.id}
                      title={node.mainImage.title}
                      alt={node.mainImage.title}
                      description={node.mainImage.description}
                      fluid={node.mainImage.fluid}
                    />
                  </Link>
                  <figcaption className="thumbnail-info mb-4 text-lg">
                    <Link to={`/education/${node.slug}`} className="thumbnail-link">
                      {node.name}
                    </Link>
                    <p className="text-sm text-gray-400">{node.author}</p>
                  </figcaption>
                </figure>
              </article>
            )
          })}

          <Divider />

          <div className="my-4">
            <h3 className="text-center text-2xl font-bold mt-4 mb-4 dark:text-gray-300 text-gray-600">Press</h3>
            {featuredPress.map(({ node }) => {
              const { id, title, description, publishDate, url, featured } = node
              const date = dayjs(publishDate).format('LL')

              return (
                <article key={id} className="flex flex-col mb-5 w-full text-center my-0 mx-auto max-w-xs md:max-w-xl">
                  <div className="text-sm dark:text-gray-500 text-gray-400 subpixel-antialiased">
                    {date}
                    {featured && <span className="bg-red-400 text-white pl-1 pr-1 text-xs ml-2">new</span>}
                  </div>

                  <div className="text-base subpixel-antialiased my-0 mx-auto max-w-sm md:max-w-xl">
                    <span className="mr-4 dark:text-gray-300 text-gray-600 font-bold">{title}</span>
                    <span className="dark:text-gray-400 text-gray-400">{description}</span>
                  </div>
                  <a href={url} className="text-blue-700 truncate md:overflow-clip" target="_blank" rel="noreferrer">
                    {url}
                  </a>
                </article>
              )
            })}
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Homepage
